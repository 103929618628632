export const queryKeys = {
  dataList: "dataList",
  restaurantList: "restaurantList",
  restaurantEdit: "restaurantEdit",
  productList: "productList",
  flowerList: "flowerList",
  categoryList: "categoryList",
  categoryGanderList: "categoryGanderList",
  categoryAgeList: "categoryAgeList",
  eventList: "eventList",
  imageList : "imageList",
  order: "order",
  orderDetails: "orderDetails",
  customers: "customers",
  notificationList: "notificationList",
  dashboard: "dashboard",
};
