import { Button, Card, Flex, Form, Input, message } from 'antd';
import React from 'react';

const ServicesPrices = () => {
  const [form] = Form.useForm();
  const watchPrice = Number(Form.useWatch('price', form)) + 15 || 0;
  const onFinish = (values) => {
    console.log('Form Values', values);
    message.success('تم حفظ التعديلات بنجاح');
  };
  return (
    <>
      <div
        className=""
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Card
          title={
            <Flex
              justify="space-between"
              align="center"
              style={{ width: '100%' }}
            >
              <h1 style={{ fontSize: '25px', fontWeight: '700' }}>
                سعر الخدمة
              </h1>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => form.submit()}
              >
                حفظ التعديلات
              </Button>
            </Flex>
          }
          style={{
            width: '100%',
          }}
        >
          <Form
            name="add-services-prices"
            layout="vertical"
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              name="price"
              label="ادخل السعر"
              rules={[
                {
                  required: true,
                },
              ]}
              style={{
                width: '400px',
              }}
            >
              <Input placeholder="ادخل السعر" type="number" min={0} />
            </Form.Item>
          </Form>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <h1
              style={{
                color: '#6D29F6',
                fontSize: '25px',
                fontWeight: '700',
              }}
            >
              الاجمالي مع الضريبه :
            </h1>
            <p style={{ fontSize: '20px', fontWeight: '700' }}>
              {watchPrice} ر.س{' '}
            </p>
          </div>
        </Card>
      </div>
    </>
  );
};

export default ServicesPrices;
